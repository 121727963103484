<template>
  <a-progress :percent="percent" size="small" status="normal" :stroke-color="{ from: '#7499ff', to: color }">
    <span slot="format" slot-scope="percent" :style="{ color: color, userSelect: 'none' }">{{ `${percent}%` }}</span>
  </a-progress>
</template>

<script>
export default {
  name: 'UsageProgress',
  props: {
    percent: { type: Number, default: 0 }
  },
  computed: {
    color () {
      if (this.percent > 85) {
        return '#e97659'
      } else if (this.percent > 75) {
        return '#fbc859'
      } else {
        return '#7499ff'
      }
    }
  }
}
</script>
