<template>
  <a-select
    :allow-clear="allowClear"
    :filter-option="false"
    :label-in-value="labelInValue"
    :mode="mode"
    :options="options"
    :placeholder="placeholder"
    :show-search="true"
    :value="value"
    @change="v => $emit('input', v)"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getHostList } from '@/api/host'

export default {
  name: 'HostSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    labelInValue: { type: Boolean, default: false },
    mode: { type: String, default: 'default' },
    placeholder: { type: String, default: '请选择主机' },
    proxy: { type: Boolean, default: false },
    value: { type: String }
  },
  data () {
    return {
      form: { page: 1, pageSize: 20 },
      options: [],
      total: 0,
      timeout: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      if (this.proxy) params.proxy = true
      getHostList(params).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(host => {
          if (this.options.filter(option => option.value === host.id).length === 0) {
            this.options.push({ key: host.id, label: host.name })
          }
        })
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.options = []
      this.form.name = value
      this.timeout = setTimeout(() => {
        this.fetch()
      }, 300)
    }
  }
}
</script>
