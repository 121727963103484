<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入 IP 子网名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-space style="margin-bottom: 16px;">
        <template v-if="permission.create">
          <a-button icon="plus" type="primary" @click="$refs.createForm.show()">创建</a-button>
          <create-form ref="createForm" @ok="() => { this.form.page = 1; this.fetch() }"></create-form>
        </template>

        <template v-if="permission.update">
          <a-button
            :disabled="disabled"
            icon="edit"
            type="link"
            @click="$refs.batchUpdateForm.show(rowSelection.selectedRowKeys)"
            :style="{ color: disabled ? '' : '#faad14' }"
          >
            编辑
          </a-button>
        </template>
      </a-space>

      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :row-selection="rowSelection"
        :scroll="{ x: scrollX }"
        @change="change"
        style="margin-bottom: 16px;"
      >
        <template slot="name" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.name">
            <a @click="$router.push({ name: 'IPSubnetDetail', params: { id: record.id } })">{{ record.name }}</a>
          </a-tooltip>
        </template>
        <template slot="usage" slot-scope="record">
          <usage-progress :percent="record" style="width: 90%;"></usage-progress>
        </template>
        <template slot="description" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
        </template>
        <template slot="scanStatus" slot-scope="record">
          <scan-status-badge :status="record"></scan-status-badge>
        </template>
        <template slot="operation" slot-scope="record">
          <a
            v-if="permission.update"
            :disabled="record.scan_status === 'running' || record.scan_status === 'pending'"
            @click="$refs.scanForm.show(record.id)"
          >
            <a-space style="user-select: none;">
              <a-icon type="security-scan"></a-icon>
              <span>扫描</span>
            </a-space>
          </a>
          <template v-if="permission.update">
            <a-divider type="vertical"></a-divider>
            <a @click="$refs.updateForm.show(record.id)" style="color: #faad14;">
              <a-space style="user-select: none;">
                <a-icon type="edit"></a-icon>
                <span>编辑</span>
              </a-space>
            </a>
          </template>
          <template v-if="permission.delete">
            <a-divider type="vertical"></a-divider>
            <a-popconfirm ok-type="danger" title="是否确定删除？" @confirm="confirm(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: #f5222d;"></a-icon>
              <a style="color: #f5222d;">
                <a-space style="user-select: none;">
                  <a-icon type="delete"></a-icon>
                  <span>删除</span>
                </a-space>
              </a>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <update-form v-if="permission.update" ref="updateForm" @ok="fetch"></update-form>
      <scan-form v-if="permission.update" ref="scanForm" @ok="fetch"></scan-form>

      <div style="height: 32px;">
        <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="page => { this.form.page = page; this.fetch() }"
          @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
          style="float: right;"
        ></a-pagination>
      </div>
    </a-card>
  </div>
</template>

<script>
import { deleteIPSubnet, getIPSubnetList } from '@/api/ip-subnet'
import UsageProgress from '@/components/progress/UsageProgress'
import { hasPermission } from '@/utils'
import ScanForm from '../modules/ScanForm'
import ScanStatusBadge from '../modules/ScanStatusBadge'
import UpdateForm from '../modules/UpdateForm'
import CreateForm from './modules/CreateForm'

export default {
  name: 'IPSubnetList',
  components: {
    CreateForm,
    ScanForm,
    ScanStatusBadge,
    UpdateForm,
    UsageProgress
  },
  data () {
    return {
      form: {
        name: '',
        page: 1,
        pageSize: 10
      },
      columns: [
        { title: 'IP 子网名称', width: 200, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'netmask', title: '子网掩码', width: 160 },
        { dataIndex: 'capacity', title: '子网容量', width: 160 },
        { dataIndex: 'usage', title: '在线率', width: 200, scopedSlots: { customRender: 'usage' } },
        { dataIndex: 'description', title: '描述', width: 200, ellipsis: true, scopedSlots: { customRender: 'description' } },
        {
          dataIndex: 'scan_status',
          title: '扫描状态',
          width: 160,
          fixed: 'right',
          scopedSlots: { customRender: 'scanStatus' },
          filters: [
            { text: this.$t('ip_subnet_status.not_started'), value: 'not_started' },
            { text: this.$t('ip_subnet_status.pending'), value: 'pending' },
            { text: this.$t('ip_subnet_status.running'), value: 'running' },
            { text: this.$t('ip_subnet_status.success'), value: 'success' },
            { text: this.$t('ip_subnet_status.failure'), value: 'failure' }
          ],
          filterMultiple: false
        },
        { title: '操作', width: 240, fixed: 'right', scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    permission () {
      return {
        create: hasPermission('ip_subnet.create'),
        update: hasPermission('ip_subnet.update'),
        delete: hasPermission('ip_subnet.delete')
      }
    },
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    },
    disabled () {
      return this.rowSelection.selectedRowKeys.length === 0
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      this.loading = true
      getIPSubnetList(params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.scan_status = filters.scan_status[0]
      this.fetch()
    },
    confirm (id) {
      deleteIPSubnet(id).then(res => {
        this.$message.success(res.message)
        if (this.total - 1 <= (this.form.page - 1) * this.form.pageSize) {
          if (this.form.page > 1) this.form.page -= 1
        }
        this.fetch()
      })
    }
  }
}
</script>
